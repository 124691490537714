export const ANALYST_PATH = '/analyst';
export const PROMPT_QUERY_PARAM_KEY = 'q';
export const ANALYST_REQUEST_ID_QUERY_PARAM_KEY = 'l';
export const ANALYST_THEME_NAME_QUERY_PARAM_KEY = 'n';
export const ANALYSIS_TYPE_QUERY_PARAM_KEY = 't';
export const INSTRUMENT_ANALYSIS_TYPE_QUERY_PARAM_KEY = 'instrument';
export const RESEARCH_ANALYSIS_TYPE_QUERY_PARAM_KEY = 'research';
export const ASK_A_QUESTION_TYPE_QUERY_PARAM_KEY = 'askAQuestion';
export const FLOATING_ACTIONS_AUTO_OPEN_QUERY_PARAM_KEY = 'actionsOpen';

export const NUCLEAR_ENERGY_AI_EXAMPLE_PROMPT =
    'With artificial intelligence demanding more compute power from data centers, therefore requiring more energy to run, which energy firms are investing in the nuclear and fuel cell capabilities to meet the growing demand?';
